




























import { Component, Vue } from 'vue-property-decorator';
import Local from '@/store/enums/Local';
import Link from '@/components/Link/Link.vue';

@Component({
  name: 'BimHome',
  components: { Link },
})
export default class BimHome extends Vue {
  private $local: any;

  get rus(): boolean {
    return this.$local.current === Local.RU;
  }
}
