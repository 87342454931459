




















import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import Language from '@/components/Header/Language.vue';
import MenuStatus from '@/store/enums/MenuStatus';
import { menuTypes } from '@/components/Header/types';
import Local from '@/store/enums/Local';

// eslint-disable-next-line import/extensions
import menu from '@/data/menu.ts';

@Component({
  components: { Language },
})
export default class Menu extends Vue {
  @Prop({
    type: Boolean,
  })
  readonly isMobile!: boolean;

  @Prop({
    type: Boolean,
  })
  readonly isHorizontal!: boolean;

  @Prop({
    type: Boolean,
  })
  readonly isLight!: boolean;

  @Prop({
    type: Boolean,
  })
  readonly isInside!: boolean;

  @Prop({
    type: Boolean,
  })
  readonly isActive!: boolean;

  private $menu: any;

  private $local: any;

  closeMenu(): void {
    this.$menu.set(this.$menu.current === MenuStatus.IS_OPEN
      ? MenuStatus.IS_HIDDEN : MenuStatus.IS_OPEN);
  }

  get rus(): boolean {
    return this.$local.current === Local.RU;
  }

  mainMenu: menuTypes[] = menu.main;

  insideMenu: menuTypes[] = menu.inside;

  get list(): menuTypes[] {
    if (this.isInside) {
      return this.insideMenu;
    }
    return this.mainMenu;
  }
}
