













































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { sliderTypes } from '@/components/Slider/types';
import SliderButtons from '@/components/Banner/SliderButtons.vue';
import Link from '@/components/Link/Link.vue';
import Header from '@/components/Header/Header.vue';
import Decoration from '@/components/Decoration/Decoration.vue';
import Local from '@/store/enums/Local';
import ColorLogo from '@/store/enums/ColorLogo';

@Component({
  components: {
    Decoration, Link, SliderButtons, Header,
  },
})
export default class BannerDesktop extends Vue {
  sliderItems: sliderTypes[] = [
    {
      id: 1,
      img: '01.jpg',
    },
    {
      id: 2,
      img: '02.jpg',
      light: true,
    },
    {
      id: 3,
      img: '03.jpg',
    },
    {
      id: 4,
      img: '04.jpg',
      light: true,
    },
  ];

  currentSlideIndex = 0;

  currentColorLogo = ColorLogo.DARK;

  private $local: any;

  private $colorLogo: any;

  get rus(): boolean {
    return this.$local.current === Local.RU;
  }

  // eslint-disable-next-line consistent-return
  lightLogo() {
    // eslint-disable-next-line no-console
    const current = this.currentSlideIndex + 1;
    const array = this.sliderItems;
    const newArr = array.find((item) => item.id === current);
    if (newArr?.light === true) {
      this.$colorLogo.set(ColorLogo.LIGHT);
    }
    if (newArr?.light === undefined) {
      this.$colorLogo.set(ColorLogo.DARK);
    }
  }

  prevSlide() {
    if (this.currentSlideIndex > 0) {
      // eslint-disable-next-line no-plusplus
      this.currentSlideIndex--;
    }
    this.lightLogo();
  }

  nextSlide() {
    if (this.currentSlideIndex >= this.sliderItems.length - 1) {
      // eslint-disable-next-line no-plusplus,no-unused-expressions
      this.currentSlideIndex = this.sliderItems.length - 2;
    }
    // eslint-disable-next-line no-plusplus
    this.currentSlideIndex++;
    this.lightLogo();
  }

  openItem(id: string) {
    return this.$router.push({
      name: 'catalog',
      params: { itemId: id },
      hash: `/catalog/civil/${id}#${id}`,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  sliderClick(): any {
    // eslint-disable-next-line no-console
    console.log('clickk');
  }
}
