





import { Component, Vue, Prop } from 'vue-property-decorator';
import { sliderItemType } from '@/components/Slider/types';

@Component({})
export default class SliderItem extends Vue {
  @Prop({
    type: Object,
  })
  readonly itemData!: sliderItemType;
}
