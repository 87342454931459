





























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Link extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  readonly label!: string;

  @Prop({
    type: Boolean,
  })
  readonly big!: boolean;
}
