










































import {
  Component, Vue, Prop,
} from 'vue-property-decorator';
import { sliderTypes } from '@/components/About/types';
import Local from '@/store/enums/Local';

@Component({
})
export default class AboutSlider extends Vue {
  softArray: sliderTypes[] = [
    {
      index: 1,
      src: 'link',
      image: 'autocad.svg',
    },
    {
      index: 2,
      src: 'link',
      image: 'revit.svg',
    },
    {
      index: 3,
      src: 'link',
      image: 'tekla.svg',
    },
    {
      index: 4,
      src: 'link',
      image: 'scad.svg',
    },
    {
      index: 5,
      src: 'link',
      image: 'lira.svg',
    },
  ];

  @Prop({
    type: Number,
    default: 0,
  })
    scrollPosition!: number;

  @Prop({
    type: Number,
    default: 210,
  })
    position!: number;

  sliderWidth = 120;

  scrollingLength = this.sliderWidth * 3;

  listWidth = (this.sliderWidth * this.softArray.length) * -1;

  get maximumLeftPosition() {
    return this.scrollPosition >= (this.listWidth + this.scrollingLength);
  }

  left(): number {
    if (this.maximumLeftPosition) {
      this.scrollPosition -= this.scrollingLength;
      return this.scrollPosition;
    }
    return this.scrollPosition;
  }

  right(): number {
    if (this.scrollPosition !== 0) {
      this.scrollPosition += this.scrollingLength;
      return this.scrollPosition;
    }
    return this.scrollPosition;
  }

  get listPosition(): string {
    return `${this.scrollPosition}px`;
  }

  get sliderWidthCss(): string {
    return `${this.sliderWidth}px`;
  }

  get rus(): boolean {
    return this.$local.current === Local.RU;
  }

  private $local: any;

  // eslint-disable-next-line class-methods-use-this
  getScrollPosition(data: any): any {
    console.log(data);
  }
}
