












import {
  Component, Vue, Emit,
} from 'vue-property-decorator';

@Component({})
export default class SliderButtons extends Vue {
  // eslint-disable-next-line class-methods-use-this
  @Emit('prevSlide')
  prevSlide(item: string | undefined) {
    return item;
  }

  // eslint-disable-next-line class-methods-use-this
  @Emit('nextSlide')
  nextSlide(item: string | undefined) {
    return item;
  }
}
