











import {
  Component,
  Vue,
} from 'vue-property-decorator';
import MenuStatus from '@/store/enums/MenuStatus';

@Component({
  components: {},
})
export default class App extends Vue {
  private $menu: any;

  closeMenu(): void {
    this.$menu.set(this.$menu.current === MenuStatus.IS_OPEN
      ? MenuStatus.IS_HIDDEN : MenuStatus.IS_OPEN);
  }
}
