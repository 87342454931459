

































import {
  Component, Vue,
} from 'vue-property-decorator';
import Local from '@/store/enums/Local';
import VAnimationStarter from '@/components/AnimationStarter/AnimationStarter.vue';

@Component({
  components: { VAnimationStarter },
})
export default class Functions extends Vue {
  list: Array<{id: number, text: string, textEng: string}> = [
    {
      id: 1,
      text: 'Проектирование',
      textEng: 'Design',
    },
    {
      id: 2,
      text: 'Экспертиза',
      textEng: 'Expertise',
    },
    {
      id: 3,
      text: 'Авторский надзор',
      textEng: 'Author’s supervision',
    },
  ];

  activeId = 1;

  private $local: any;

  get rus(): boolean {
    return this.$local.current === Local.RU;
  }

  topMargin = 150;

  rootMargin(id: number): string | undefined {
    if (id === 1) {
      return `0px  0px -${this.topMargin}px 0px`;
    }
    if (id === 2) {
      return `0px  0px -${this.topMargin * 2}px 0px`;
    }
    if (id === 3) {
      return `0px  0px -${this.topMargin * 2.5}px 0px`;
    }
    return '';
  }
}
