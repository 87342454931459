import { render, staticRenderFns } from "./AboutSlider.vue?vue&type=template&id=72ce82b1&scoped=true&"
import script from "./AboutSlider.vue?vue&type=script&lang=ts&"
export * from "./AboutSlider.vue?vue&type=script&lang=ts&"
import style0 from "./AboutSlider.vue?vue&type=style&index=0&id=72ce82b1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72ce82b1",
  null
  
)

export default component.exports