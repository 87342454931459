



















































import {
  Component, Vue,
} from 'vue-property-decorator';
import Link from '@/components/Link/Link.vue';
import Local from '@/store/enums/Local';

@Component({
  components: { Link },
})
export default class Contacts extends Vue {
  private $local: any;

  get rus(): boolean {
    return this.$local.current === Local.RU;
  }
}
