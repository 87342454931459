





















import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import Local from '@/store/enums/Local';

@Component({})
export default class Language extends Vue {
 @Prop({
   type: Boolean,
   default: true,
 })
  readonly isRuLocale!: boolean;

  @Prop({
    type: Boolean,
  })
 readonly isMobile!: boolean;

  @Prop({
    type: Boolean,
  })
  readonly isLight!: boolean;

  private $local: any;

  get localStatus(): boolean {
    return this.$local.current === Local.RU;
  }

  changeLang(): void {
    this.$local.set(this.$local.current === Local.RU ? Local.ENG : Local.RU);
  }
}
