




























import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import Menu from '@/components/Header/Menu.vue';
import Language from '@/components/Header/Language.vue';
import MenuStatus from '@/store/enums/MenuStatus';

@Component({
  components: { Language, Menu },
})
export default class Header extends Vue {
  @Prop({
    type: Boolean,
  })
  readonly lightMenu!: boolean;

  @Prop({
    type: Boolean,
  })
  readonly lightLogo!: boolean;

  @Prop({
    type: Boolean,
  })
  readonly insideMenu!: boolean;

  @Prop({
    type: Boolean,
  })
  readonly isRus!: boolean;

  @Prop({
    type: Boolean,
  })
  readonly horizontalMenu!: boolean;

  toggleLang() {
    return this.isRus === !this.isRus;
  }

  private $menu: any;

  private $layout: any;

  private $colorLogo: any;

  openMenu(): void {
    this.$menu.set(this.$menu.current === MenuStatus.IS_HIDDEN
      ? MenuStatus.IS_OPEN : MenuStatus.IS_HIDDEN);
  }
}
