









































import { Component, Vue, Prop } from 'vue-property-decorator';
import { AdvantagesArray } from '@/components/Advantages/types';
import Decoration from '@/components/Decoration/Decoration.vue';
import Local from '@/store/enums/Local';

@Component({
  components: { Decoration },
})
export default class Advantages extends Vue {
  @Prop({
    type: Array,
  })
  readonly array!: AdvantagesArray[];

  private $local: any;

  get rus(): boolean {
    return this.$local.current === Local.RU;
  }
}
