





import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Footer extends Vue {
  // eslint-disable-next-line class-methods-use-this
  get currentYear() {
    return new Date().getFullYear();
  }
}

