import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=b5bb84e0&scoped=true&"
import script from "./Banner.vue?vue&type=script&lang=ts&"
export * from "./Banner.vue?vue&type=script&lang=ts&"
import style0 from "./Banner.vue?vue&type=style&index=0&id=b5bb84e0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5bb84e0",
  null
  
)

export default component.exports