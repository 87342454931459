


















import { Component, Vue, Prop } from 'vue-property-decorator';
import { sliderTypes } from '@/components/Slider/types';
import SliderItem from '@/components/Slider/SliderItem.vue';

@Component({
  components: { SliderItem },
})
export default class Slider extends Vue {
  sliderItems: sliderTypes[] = [
    { id: 1, name: 'img1', img: '1.jpg' },
    { id: 2, name: 'img2', img: '2.jpg' },
    { id: 3, name: 'img3', img: '3.jpg' },
    { id: 4, name: 'img4', img: '4.jpg' },
    { id: 5, name: 'img5', img: '5.jpg' },
    { id: 6, name: 'img6', img: '3.jpg' },
  ];

  currentSlideIndex = 0;

  @Prop({
    type: Number,
    default: 0,
  })
  readonly interval!: number;

  prevSlide() {
    if (this.currentSlideIndex > 0) {
      // eslint-disable-next-line no-plusplus
      this.currentSlideIndex--;
    }
  }

  nextSlide() {
    if (this.currentSlideIndex >= this.sliderItems.length - 1) {
      this.currentSlideIndex = 0;
    }
    // eslint-disable-next-line no-plusplus
    this.currentSlideIndex++;
  }
  //
  // mounted() {
  //   if (this.interval > 0) {
  //     setInterval(() => {
  //       this.nextSlide();
  //     }, this.interval);
  //   }
  // }
}
