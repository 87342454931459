













import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Decoration extends Vue {
  @Prop({
    type: [Number, String],
    default: 230,
  })
  readonly size!: [number, string];

  @Prop({
    type: [Number, String],
    default: 2,
  })
  readonly width!: [number, string];

  @Prop({
    type: String,
    default: 'white',
  })
  readonly color!: string;
}
