















import {
  Component, Vue,
} from 'vue-property-decorator';
import { teamTypes } from '@/components/Team/types';

@Component({})
export default class Team extends Vue {
  teamArray: teamTypes[] = [
    {
      index: 1,
      src: 'team-1',
      name: 'Иван',
      position: 'Инженер',
    },
    {
      index: 2,
      src: 'team-2',
      name: 'Дарья',
      position: 'Инженер',
    },
    {
      index: 3,
      src: 'team-4',
      name: 'Иван',
      position: 'Инженер',
    },
    {
      index: 4,
      src: 'team-3',
      name: 'Дарья',
      position: 'Инженер',
    },
    {
      index: 5,
      src: 'team-2',
      name: 'Дарья',
      position: 'Инженер',
    },
    {
      index: 6,
      src: 'team-4',
      name: 'Иван',
      position: 'Инженер',
    },
    {
      index: 7,
      src: 'team-3',
      name: 'Дарья',
      position: 'Инженер',
    },
  ];
}
